import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isObjectLike from 'lodash/isObjectLike';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import { isObservable, toJS } from 'mobx';

import { type Prettify } from '../../types/util';

import { cloneDeep } from './cloneDeep';

export function typedEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

export function typedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}

/**
 * Detect if we should use `toJS` (if observable) or `cloneDeep` to clone an object.
 * @param obj Object to clone.
 */
export function cloneUnknown<T = any>(obj: T): T {
  if (isObservable(obj)) {
    return toJS(obj);
  } else {
    return cloneDeep(obj);
  }
}

function periodSplit(field: string) {
  // Basically doing `field.split('.')` but excluding escaped periods "\\."
  // Since JS RegEx doesn't have lookbehind you have to mimic it with a reverse.
  // https://stackoverflow.com/questions/45131764/javascript-split-on-char-but-ignoring-double-escaped-chars
  const _periodSplit = field
    .split('')
    .reverse()
    .join('')
    .split(/\.(?!\\)/)
    .reverse()
    .map((x) => x.split('').reverse().join(''));

  return _periodSplit;
}

/**
 * Extract value from dot.notation
 */

export function extractDotValue(field: string, data?: Record<string, any>): any {
  // Need to manually "escape" the `\.` in the key names because they won't be preserved in the JS Object `data`.
  return periodSplit(field).reduce((accum, key) => (accum ? accum[key.replace(/\\\./g, '.')] : accum), data || {});
}

/**
 * Extract value from dot.notation and formatted key
 */

export function extractDotKeyValue(field: string, data?: Record<string, any>): { key: string; value: any } {
  const split = periodSplit(field);

  let finalKey = '';

  const finalValue = split.reduce((accum, key) => {
    let indexKey = key;

    if (/\\\./g.test(key)) {
      indexKey = key.replace(/\\\./g, '.');

      finalKey = `${finalKey}["${indexKey}"]`;
    } else {
      finalKey = `${finalKey}${finalKey.length ? '.' : ''}${indexKey}`;
    }

    return accum ? accum[indexKey] : accum;
  }, data || {});

  return {
    key: finalKey,
    // Need to manually "escape" the `\.` in the key names because they won't be preserved in the JS Object `data`.
    value: finalValue,
  };
}

type FlatObject = Record<string, any>;
/**
 * Transform a nested object into a map of dot.notation keys to values
 * Also escape key names that contain a period. Example...
 * { "owner.name": "Bill" }
 * ...becomes...
 * owner\.name
 */
export function flattenObject(obj: any, path: any[] = []): FlatObject {
  return Array.isArray(obj) || !isObject(obj)
    ? { [path.join('.')]: obj }
    : Object.keys(obj).reduce(
        // @ts-expect-error unchecked index
        (accum, key) => merge(accum, flattenObject(obj[key], [...path, key.replace(/\./g, '\\.')])),
        {}
      );
}

export function unflattenObject(flatObj: FlatObject): any {
  return Object.keys(flatObj).reduce(
    (result, key) => {
      const keys = key.split(/(?<!\\)\./).map((k) => k.replace(/\\\./g, '.'));
      let current = result;

      keys.forEach((part, i) => {
        if (i === keys.length - 1) {
          current[part] = flatObj[key];
        } else {
          current = current[part] = current[part] || {};
        }
      });

      return result;
    },
    {} as Record<string, any>
  );
}

type LeafNode<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T as keyof any]: LeafNode<T[K]>;
    }
  : T;

/**
 * Transform a nested object into a flat object without change to the leaf nodes.
 * {"a": {"b": 2}, "c": 3} becomes {"b": 2, "c": 3}
 */
export function flattenObjectWithoutPreservingParents<T extends Record<string, unknown>>(obj: T): LeafNode<T> {
  const result = {} as LeafNode<T>;

  function flatten(source: Record<string, unknown>, target: Record<string, unknown>) {
    for (const key in source) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        flatten(source[key] as Record<string, unknown>, target);
      } else {
        target[key] = source[key];
      }
    }
  }

  flatten(obj, result);

  return result;
}

/**
 * Takes an object or array and recursively removes all keys with a value of `null`.
 *
 * This is a lot of extra type work for not a lot of extra type safety
 * All these generics do is remove `null`s from the return type
 * If this is scary and you want it gone, revert the commit where these types and this comment were added
 */
type RemoveTupleNull<T extends unknown[]> = T extends [infer first, ...infer rest]
  ? rest extends Record<string, never>
    ? never
    : first extends null
    ? RemoveTupleNull<rest>
    : [RemoveNull<first>, ...RemoveTupleNull<rest>]
  : [];
type RemoveArrayNull<T extends unknown[]> = number extends T['length'] ? RemoveNull<T[number]>[] : RemoveTupleNull<T>;
type RemoveNull<T> = T extends unknown[]
  ? RemoveArrayNull<T>
  : T extends Record<string, unknown>
  ? Prettify<{
      [k in keyof T as T[k] extends null ? never : k]: T[k] extends object ? RemoveNull<T[k]> : T[k];
    }>
  : Exclude<T, null>;

export function deepRemoveNullValues<T extends unknown[] | Record<string, unknown>>(input: T): RemoveNull<T> {
  if (Array.isArray(input)) {
    return [...input]
      .filter((item) => !isNil(item))
      .map((item) => (isObject(item) ? deepRemoveNullValues(item as T) : item)) as never;
  }

  const inputClone = { ...input };
  // eslint-disable-next-line guard-for-in
  for (const key in inputClone) {
    const value = inputClone[key];
    if (value === null) {
      delete inputClone[key];
    } else if (isObjectLike(value)) {
      inputClone[key] = deepRemoveNullValues(value as unknown as T) as never;
      if (isPlainObject(value) && isEmpty(inputClone[key])) {
        delete inputClone[key];
      }
    }
  }

  return inputClone as never;
}

export function isValueEmptyAfterRemovingNulls(value: any): boolean {
  if (isNil(value) || value === '') {
    return true;
  }

  const isArrayOrObject = Array.isArray(value) || isObject(value);

  if (isArrayOrObject) {
    const removedNulls = deepRemoveNullValues(value as any);

    return isEmpty(removedNulls) || isNil(removedNulls) || removedNulls === '';
  }

  return isNil(value) || value === '';
}

export function cleanObject<T extends Record<string, any> | undefined>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return typedEntries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc![key] = value;
    }

    return acc;
  }, {} as T);
}
